import React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'

const tagLink = css`
  text-decoration: none;
  margin-right: 5px;
  
  &:last-of-type {
    margin-right: 0;
  }
`

const oneTag = css`
  background-color: #BA2636;
  color: #fff;
  padding: 2px;
  &:before {
    content: '#';
  }
  
  &:after {
    content: ' ';
  }
`

const TagList = ({ tags }) => {
  return (
    <div>
      {tags.map(tag =>
        <Link key={tag} to={`/tags/${tag}`} className={tagLink}>
          <span className={oneTag}>{tag}</span>
        </Link>
      )}
    </div>
  )
}

export default TagList