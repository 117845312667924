import React from 'react'
import { css } from 'linaria'
import { StaticImage } from "gatsby-plugin-image";

const authorArea = css`
  display: flex;
  align-items: flex-end;
`

const authorImage = css`
  width: min(100px, 100%);
  & img {
    width: 100%;
    border-radius: 50%;
  }
`

const authorNameHead = css`
  flex: 1;
`

const authorAreaFoot = css`

`

const authorAreaFootTitle = css`
  padding: 20px 0;
  
  span {
    background:linear-gradient(transparent 60%, #ff6 60%);
  }
`

const flexFoot = css`
  display: flex;
`

const authorNameFoot = css`
  font-size: clamp(20px, 2.2vw, 30px);
  margin-bottom: 16px;
`

const snsLogos = css`
  display: flex;
  margin-bottom: 16px;
  
  > a {
    width: 40px;
    margin-right: 20px;
  }
`

const instagram = css`
  display: block;
`

const authorInfo = css`
  flex: 1;
  padding-left: 1em;
`

const Takahashi = ({ position }) => {
  const Name = "高橋 まり"
  return (
    <>
      {(() => {
        if (position === `head`) {
          return (
            <div className={authorArea}>
              <div className={authorImage}>
                <StaticImage
                  src="../../images/author/takahashi.jpg"
                  alt=""
                  placeholder="blurred"
                  quality="40"
                />
              </div>
              
              <div className={authorNameHead}>
                Author<br />{Name}
              </div>
            </div>
          )
        } else if (position === `foot`) {
          return (
            <div className={authorAreaFoot}>
              <div className={authorAreaFootTitle}><span>この記事を書いた人</span></div>
              <div className={flexFoot}>
                <div className={authorImage}>
                  <StaticImage
                    src="../../images/author/takahashi.jpg"
                    alt=""
                    placeholder="blurred"
                    quality="40"
                  />
                </div>
                <div className={authorInfo}>
                  <div className={authorNameFoot}>{Name}</div>
                  <div className={snsLogos}>
                    <a className={instagram} href="https://www.instagram.com/mario_mosakuchu/" target="_blank" rel="noopener noreferrer">
                      <StaticImage
                        src="../../images/common/instagram.svg"
                        alt="Shiva"
                        placeholder="blurred"
                        quality="40"
                      />
                    </a>
                  </div>
                  
                  <p>
                    札幌からリモートで参加するWebエンジニア。<br />イラストも描く。Linuxに興味を持って模索中。
                  </p>
                </div>
              </div>
            </div>
          )
        } else {
          
        }
      })()}
    </>
  )
}

export default Takahashi