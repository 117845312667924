import React from 'react'
import { graphql, Link } from 'gatsby'
import { css } from 'linaria'
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../layouts'
import Seo from '../components/SEO'
import TagList from '../components/TagList';
import Koshio from '../components/author/Koshio';
import Takahashi from '../components/author/Takahashi';
const moment = require('moment');

const kvArea = css`
  width: min(100%, 1280px);
`

const blogTitle = css`
  font-size: clamp(24px, 3.2vw, 48px);
  margin-bottom: 5px;
`

const postTime = css`
  color: #555;
  margin-bottom: 5px;
`

const postContents = css`
  margin-top: 5%;
  margin-bottom: 5%;
`

const pnIndex = css`
  background-color: #EACDCD;
  padding: 20px 2em;
  a {
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
      color: red;
    }
  }
`

const nextTitle = css`
  
`

const previousTitle = css`
  margin-bottom: 5px;
`

const Post = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const title = post.frontmatter.title
  const date = post.frontmatter.date
  const html = post.html
  const image = post.frontmatter.cover.childImageSharp.gatsbyImageData.src
  const { prev, next } = pageContext
  const description = post.frontmatter.description
  let arrAuthordata = {}
  
  if (post.frontmatter.author === `koshio`) {
    arrAuthordata["head"] = (<Koshio position={'head'} />)
    arrAuthordata["foot"] = (<Koshio position={'foot'} />)
  } else if (post.frontmatter.author === `takahashi`) {
    arrAuthordata["head"] = (<Takahashi position={'head'} />)
    arrAuthordata["foot"] = (<Takahashi position={'foot'} />)
  } else {
    arrAuthordata["head"] = (<Koshio position={'head'} />)
    arrAuthordata["foot"] = (<Koshio position={'foot'} />)
  }
  
  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        banner={image}
        pathname={post.frontmatter.path}
        article
      />
      <section>
        <div className={kvArea}>
          <GatsbyImage
            image={post.frontmatter.cover.childImageSharp.gatsbyImageData}
            alt={post.frontmatter.title} />
        </div>
        <h1 className={blogTitle}>{title}</h1>
        <TagList tags={post.frontmatter.tags || []} />
        <div className={postTime}><time dateTime={date}>{moment(date).format("YYYY/MM/DD")}</time></div>
        
        {arrAuthordata["head"]}
        
        <div className={`websContents ${postContents}`} dangerouslySetInnerHTML={{ __html: html }} />
        
        {arrAuthordata["foot"]}
        
        <div className={pnIndex}>
          <div className={previousTitle}>
            {prev &&
              <Link to={prev.frontmatter.path}>
                [前の記事] {prev.frontmatter.title}
              </Link>
            }
          </div>
          <div className={nextTitle}>
            {next &&
              <Link to={next.frontmatter.path}>
                [次の記事] {next.frontmatter.title}
              </Link>
            }
          </div>
        </div>
      </section>
    </Layout>
  );
}
export const query = graphql`query ($pathSlug: String!) {
  markdownRemark(frontmatter: {path: {eq: $pathSlug}}) {
    html
    frontmatter {
      path
      date
      title
      tags
      author
      cover {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
      description
    }
  }
}
`
export default Post